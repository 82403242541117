import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
// import { PATH_DASHBOARD,PATH_USER } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated ,user } = useAuth();
  console.log(user,'required')
  if (isAuthenticated && user.visits === 1) {
    console.log(user.visits,"user.visits from Guest Gurads  1")
    return <Navigate to='user/editprofile' />;

  }
  if (isAuthenticated && user.visits > 1) {
    console.log(user.visits,"user.visits from Guest Gurads  >1")

    return <Navigate to='user/profile' />;
  }

  return <>{children}</>;
}
