import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import PropTypes from 'prop-types';



LoadingButton.propTypes = {
    isDisabled:PropTypes.bool,
  spinnerComp:PropTypes.string,
    size:PropTypes.string,
    role:PropTypes.string,
    buttonVarient:PropTypes.string,
    buttonText:PropTypes.string,
    loadingText:PropTypes.string,
    animation:PropTypes.string,
    spinnerVarient:PropTypes.string,

}
export default function LoadingButton({isDisabled,spinnerComp,animation,size,role,buttonVarient,buttonText,spinnerVarient,loadingText,...other}) {
    return (
      <Button variant={buttonVarient} {...other}>
      {
        isDisabled?
        <Spinner
          as={spinnerComp}
          animation={animation}
          size={size}
          role={role}
          aria-hidden="true"
          variant={spinnerVarient}
        />
        :null
      }
        {isDisabled?loadingText:buttonText}
      </Button>
  );
}
