/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Comment,
  VideoDetails,
  VideoPlayer,
} from "../../components/webpage/TvSerialDetail";
import useWindowDimensions from "../../hooks/getWindowDimensions";
// import useAuth from "../../hooks/useAuth";
import LoadingScreen from "../../components/LoadingScreen";
import Iconify from "../../components/Iconify";
// import AllSeasons from "../../components/webpage/TvSerialDetail/AllSeasons";
import Swal from "sweetalert2";
function Play() {
  const { width } = useWindowDimensions();
  const [comments, setComments] = useState();
  const [loading, setLoading] = useState(false);
  const [, setId] = useState();
  // const { id } = useParams();
  // console.log(id, "idtvserialwacth");
  // const { state } = useLocation();
  // const location = useLocation()
  const [firstEpispde, SetfirestEpispde] = useState([]);
  const [EpispdeURL, SetEpispdeURL] = useState([]);
  const [seasons, SetSeasons] = useState([]);
  const [Video_Data, setVideoData] = useState([]);
  const navigate = useNavigate();


  // Get video --------------------------------------------------------------

  const videoData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`api/get/media/random`);
      console.log(response, "video");
      setVideoData(response?.data?.media);
      SetEpispdeURL(response?.data?.media?.url);
      setId(response?.data?.media?.id);
      getComment(response?.data?.media?.id);
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", `${error.message}`, "error");

      console.error(error);

      setLoading(false);
    }
  };

  useEffect(() => {
    videoData();
  }, []);
  // OnLike

  const onLike = async (id) => {
    console.log("Ahnmed ID", id);

    try {
      const response = await axios.get(`/api/admin/media/like/${id}`);
      toast.success(`${response.data.message}`);

      videoData();
    } catch (error) {
      Swal.fire("Something went wrong", `${error.message}`, "error");
    }
  };

  const getComment = async (id) => {
    try {
      const response = await axios.get(`api/admin/media/get/comments/${id}`);
      console.log(response, "comment log");
      setComments(response.data.comments);
    } catch (error) {
      // toast.error("No Comment found");
    }
  };
  console.log(firstEpispde[0]?.url, "abcdabcd");
  useEffect(() => {
   
    videoData();
    // getAllSeasons();
  }, []);

  return (
    <>
      <>
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
  
              <div className="container-fluid no-gutters mt-5">
                <div
                  className="right text-center"
                  style={{ padding: "px", backgroundColor: "#111111" }}
                >
                  <a
                    href="#"
                    style={{
                      fontSize: "10rem",
                      color: "white",
                      fontFamily: '"Crimes Times Six"',
                    }}
                  >
                    {Video_Data?.title}
                  </a>
                </div>
                <div
                  className="follow mt-1 text-danger"
                  style={{
                    position: width > 1000 ? "absolute" : "absolute",
                    zIndex: 1,
                    opacity: 1,
                    right: 6,
                  }}
                >
                  <Iconify
                    icon="ci:close-big"
                    color="red"
                    style={{ color: "#ff0000", height: 50, width: 50 ,}}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <div className="row no-gutters">
                  <div className="col-lg-12 m-auto">
                    <VideoPlayer url={EpispdeURL} />
                  </div>
                  <div className="col-lg-5 m-auto">
                    <VideoDetails
                      title={Video_Data?.title}
                      clientId={Video_Data?.id}
                      videoId={Video_Data?.id}
                      commentCount={Video_Data?.comments_count}
                      description={Video_Data?.subDes}
                      likeCount={Video_Data?.likes_count}
                      img={Video_Data?.thumbs}
                      isLiked={Video_Data?.is_like}
                      date={Video_Data?.date}
                      onLike={onLike}
                    />
                    
                    {seasons}
                  </div>
                  {/* <div className="col-lg-6 mt-20-auto">
                    <AllSeasons />
                  </div> */}
                  <div className="col-lg-6 mt-20-auto mt-5">
                  <Comment
                      comment={comments}
                      getComment={getComment}
                      getVideo={videoData}
                      />
                      </div>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
          </>
        )}
      </>
    </>
  );
}
export default Play;
