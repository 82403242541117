import React from 'react'
import {Link, useNavigate} from 'react-router-dom'

// 
// import Image from "../../Image";


const CommentList = ({ comments, ...others }) => {
  console.log(comments)
  const navigate = useNavigate()
  return (
    <>
      <div className="comment_area clearfix " style={{
        maxHeight: '39vh',
        minHeight: '38vh',
        overflowX: 'hidden',
        overflowY: "scroll",
        paddingTop: 0,
        paddingBottom: 0,
      }}>
        {/* Section Title */}
        <ul style={{padding:'25px 10px'}}>
          {/* Single Comment Area */}
          {
            comments !== null && comments !== undefined ?

              comments?.map((e) =>
                  // console.log("Mohsin ==>", e)
                <li className="single_comment_area">
                  {/* Comment Content */}
                  <div className="comment-content d-flex">
                    {/* Comment Author */}
                    <div className="comment-author">
                      <div class="avatar">
                        <div class="avatar-contianer comment">
                          <img src={e.picture !== '' && e.picture !== null ? e.picture : '/assets/website/images/Title.png'} alt="Profile Img"  onClick={() => { navigate(`/profile/${e.client_id}`)}}/>
                        </div>
                      </div>
                    </div>
                    {/* Comment Meta */}
                    <div className="comment-meta">
                      <Link to={`/profile/${e.client_id}`}>
                      <label href="#" className="comment-date">
                        {e.comment_of}
                      </label>
                      </Link>
                      <h6>
                        {e.date}
                      </h6>
                      <div style={{ overflowWrap: "anywhere" }}>

                        <p>{e.comments}</p>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* <button className="reply">
                Reply
              </button> */}
                      </div>
                    </div>
                  </div>

                </li>
              )
              :
              <div className='text-white text-center'>
                no comment 
              </div>
          }


        </ul>
      </div>
    </>
  )
}

export default CommentList