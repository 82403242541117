import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// 
import { HelmetProvider } from 'react-helmet-async';
// taostify
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// 
import 'react-perfect-scrollbar/dist/css/styles.css';
// Context
import { CollapseDrawerProvider } from './contexts/IsCollasped'
// 

import Router from './routes';

// react tab css
import 'react-tabs/style/react-tabs.css';

import './App.css'
function App() {
 
  return (

      <HelmetProvider>
        <BrowserRouter>
          <ToastContainer />
          <CollapseDrawerProvider>
            <Router />
          </CollapseDrawerProvider>
        </BrowserRouter>
      </HelmetProvider>

  );
}

export default App;
