import React from "react";
import LoginComponent from "../../../components/auth/LoginComponent";
import AuthHeader from "../../../components/layout/AuthHeader";
import FooterComponent from "../../../components/layout/FooterComponent";


 const Login = () => {
    return(
        <>
        <AuthHeader />
            <LoginComponent />
            <FooterComponent />
            
            
        </>
    )
}
export default Login