import React from "react";
import FooterComponent from "../../components/layout/FooterComponent";
// Hooks
import useAuth from "../../hooks/useAuth";


const Footer = () =>{
    const {isAuthenticated} = useAuth();
    return(
        <>
        {
            isAuthenticated ?
            null
            :
            <FooterComponent />
        }
        </>
    )
}
export default Footer