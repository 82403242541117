/* eslint-disable jsx-a11y/anchor-is-valid */
import {  Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

//
import MainFooter from "./MainFooter";
import MainHeader from "./MainHeader";
import AuthHeader from "../../components/layout/AuthHeader";
import { useContext } from "react";
import { CollapseDrawerContext } from "../../contexts/IsCollasped";
import useWindowDimensions from "../../hooks/getWindowDimensions";
// ----------------------------------------------------------------------

export default function MainLayout() {
  const Collaspe = useContext(CollapseDrawerContext);
  const { width } = useWindowDimensions();
  // const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  // const isHome = pathname === "/";

  return (
    <>
      {isAuthenticated ? <MainHeader /> : <AuthHeader />}
      <div
        onClick={() => {
          if (width < 999) {
            Collaspe.onToggleCollapse(false);
          }
        }}
        // onTouchEnd={Collaspe.onToggleCollapse(false)}
      >
        <Outlet />
      </div>
      <MainFooter />
      <div className="templatemo_bottom" style={{height:"50px", marginTop: "50px"}}>
        <div className="container d-flex justify-content-center align-items-center flex-column ">
          <div style={{ color: "#fff", marginBottom: "30px", marginTop: "20px" }}>
            Copyright © 2023{" "}
            <a href="#" style={{ color: "#DB0000" }}>
              Sarealtv / Sareal Entertainment, LLC
            </a>
          </div>
        {!isAuthenticated ? (
          <div className="right mb-3">
            <a href="www.linkedin.com/in/sarealtv">
              <div className="fa fa-linkedin soc" />
            </a>
            <a href="https://twitter.com/Sarealtv">
              <div className="fa fa-twitter soc" />
            </a>
            <a href="https://www.instagram.com/sarealtv">
              <div className="fa fa-instagram soc" />
            </a>
            <a href="https://www.youtube.com/sarealent">
              <div className="fa fa-youtube-play soc" />
            </a>
            <a href="https://www.facebook.com/SaRealEnt/?ref=bookmarks">
              <div className="fa fa-facebook soc" />
            </a>
          </div>
        ) : null}
        </div>
        
      </div>
    </>
  );
}
