/* eslint-disable react/jsx-pascal-case */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard'
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
// components
import LoadingScreen from '../components/LoadingScreen';
import Play from '../pages/webpage/Play';
// import { PATH_AFTER_LOGIN } from '../config';


// ----------------------------------------------------------------------


const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <SignUp />
            </GuestGuard>
          ),
        },
      ],
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        {element: <Navigate to="/home"/>, index: true },
        {path: 'home', element: <Home />, index: true },
        {
          path: 'madrobot', element: <MadRobot />,
          children: [

            { element: <MadRobot />, index: true },
            { path: 'webgl', element: <MadRobotWebGl /> },
          ]
        },
        { path: '/games', element: <Game /> },
        { path: '/nfts', element: <NFTS /> },
        { path: '/studio', element: <Studio /> },
        { path: '/digitallocker', element: <DigitalLocker /> },
        { path: '/video', element: <Videos /> },
        { path: '/photos', element: <Photos /> },
        { path: '/motiongraphics', element: <MotionGraphics /> },
        { path: '/cartoonanimations', element: <CartoonAnimations /> },
        { path: '/visualfx', element: <VisualFx /> },
        { path: '/messages', element: <MessageComponent/> },
        { path: '/movies', element: <Movies /> },
        { path: '/movies/details/:id', element: <Moviesdetail /> },
        { path: '/movies/watch/:id', element: <MoviesWatch /> },
        // { path: '/tvSerial/watch/:id', element: <TvSerialWatch /> },
        { path: '/tvSerial/watch/:id/1/1', element: <TvSerialWatch /> },
        {path:'/tvSerial/watchEpisode/:id/:Seasonid/:EpisodeNumber',element:<EpisodeWatch/>},
        { path: '/messages', element: <MessageComponent/> },
        { path: '/tvshows', element: <Tv_serial /> },
        { path: '/tv_show/details/:id', element: <Tv_serial_detail /> },
        { path: '/publishedphotos', element: <PublishedPhotos /> },
        { path: '/socialmedia', element: <SocialMedia /> },
        { path: '/contactus', element: <Contact /> },
        // { path: '/login', element: <Login /> },
        // { path: '/signup', element: <SignUp /> },
        { path: '/profile/:userid', element: <MyProfile /> },
        { path: '/startprofile', element: <StartProfile /> },
        { path: '/privacypolicy', element: <PrivacyPolicy /> },
        { path: '/termsofservices', element: <TermsOfServices /> },
        { path: '/followerList/:userid', element: <FollowerList /> },
        { path: '/list/:userid', element: <mylist /> },
        { path: '/listview/:id', element: <ListGrid /> },
        { path: '/activation', element: <EmailVerifcation /> },
        { path: '/search', element: <Search /> },
        { path: '/Subscription', element: <Subscription /> },
        { path: '/play', element: <Play /> },




      ],
    },
    // USER LOGIN
    {
      path: 'user', element: ( 
      <AuthGuard >
      <MainLayout />
      </AuthGuard>
      ),
      children: [
        { path: "editprofile", element: <EditProfile /> },
        { path: "profile", element: <MyProfile /> },
        { path: "follower", element: <FollowerList /> },
        { path: "myList", element: <MyList /> },
        { path: 'listview/:id', element: <ListGrid /> },
        {path:'chat/:id',element:<Chat/>},
        {path:'inbox',element:<Chat/>}


      ]
    },
    { path: '/videodetail/:id', element: <VideoDetails /> },
    { path: '404', element: <NotFound /> },
    { path: 'comingsoon', element: <CommingSoon /> },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/login')));
const SignUp = Loadable(lazy(() => import('../pages/auth/signup')));


// Main Routes


const Home = Loadable(lazy(() => import('../pages/webpage/Home')));
const MadRobot = Loadable(lazy(() => import('../pages/webpage/madrobot/MadRobot')));
const MadRobotWebGl = Loadable(lazy(() => import('../pages/webpage/madrobot/MadRobotWebGl')));
const Game = Loadable(lazy(() => import('../pages/webpage/Game')));
const NFTS = Loadable(lazy(() => import('../pages/webpage/NFTS')));
const Studio = Loadable(lazy(() => import('../pages/webpage/Studio')));
const DigitalLocker = Loadable(lazy(() => import('../pages/webpage/digitallocker/DigitalLocker')));
const Videos = Loadable(lazy(() => import('../pages/webpage/digitallocker/Videos')));
const Photos = Loadable(lazy(() => import('../pages/webpage/digitallocker/Photos')));
const MotionGraphics = Loadable(lazy(() => import('../pages/webpage/digitallocker/MotionGraphics')));
const CartoonAnimations = Loadable(lazy(() => import('../pages/webpage/digitallocker/CartoonAnimations')));
const VisualFx = Loadable(lazy(() => import('../pages/webpage/digitallocker/VisualFx')));
const Movies = Loadable(lazy(() => import('../pages/webpage/Movies')));
const MoviesWatch = Loadable(lazy(() => import('../pages/webpage/MoviesWatch')));
const MessageComponent = Loadable(lazy(() => import('../pages/webpage/Message')));
const PublishedPhotos = Loadable(lazy(() => import('../pages/webpage/digitallocker/PublishedPhotos')));
const SocialMedia = Loadable(lazy(() => import('../pages/webpage/digitallocker/SocialMedia')));
const Contact = Loadable(lazy(() => import('../pages/webpage/Contact')));
const MyProfile = Loadable(lazy(() => import('../pages/webpage/MyProfile')));
const StartProfile = Loadable(lazy(() => import('../pages/webpage/StartProfile')));
const TermsOfServices = Loadable(lazy(() => import('../pages/webpage/TermsOfServices')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/webpage/PrivacyPolicy')));
const VideoDetails = Loadable(lazy(() => import('../pages/webpage/VideoDetails')));
const TvSerialWatch = Loadable(lazy(()=> import('../pages/webpage/Tv_Serial_Watch')))
const EpisodeWatch = Loadable(lazy(()=> import('../pages/webpage/EpisodeWatch')))
const EditProfile = Loadable(lazy(() => import('../pages/webpage/EditProfile')));
const FollowerList = Loadable(lazy(() => import('../pages/webpage/FollowerList')));
const MyList = Loadable(lazy(() => import('../pages/webpage/MyList')));
const ListGrid = Loadable(lazy(() => import('../pages/webpage/ListGrid')));
const Chat = Loadable(lazy(() => import('../pages/webpage/Chat')));
const EmailVerifcation = Loadable(lazy(() => import('../pages/webpage/EmailVerification')));
const Moviesdetail = Loadable(lazy(() => import('../pages/webpage/Moviedetail')));
const Tv_serial = Loadable(lazy(() => import('../pages/webpage/Tv_serial')));
const Tv_serial_detail = Loadable(lazy(() => import('../pages/webpage/Tv_serial_detail')));
const Search = Loadable(lazy(() => import('../pages/webpage/SearchPage')));
const Subscription = Loadable(lazy(() => import('../pages/webpage/Packeges/Index')));





// error
const NotFound = Loadable(lazy(() => import('../pages/Error/NotFound')));
const CommingSoon = Loadable(lazy(() => import('../pages/Error/CommingSoon')));
