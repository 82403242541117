/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//
import PerfectScrollbar from "react-perfect-scrollbar";
import Pusher from "pusher-js";
// hooks
import useAuth from "../../hooks/useAuth";

// Path
import { PATH_AUTH, PATH_USER } from "../../routes/paths";
import Iconify from "../Iconify";
//  Utils
import { search } from "../../utils/SearchRequestHandler";
import useWindowDimensions from "../../hooks/getWindowDimensions";
//
import { CollapseDrawerContext } from "../../contexts/IsCollasped";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

// -----------------------------------------------------------------------------------
const HeaderComponent = () => {
  const Collaspe = useContext(CollapseDrawerContext);

  const { width } = useWindowDimensions();

  const [, setLoading] = useState(false);

  const { pathname } = useLocation();

  const [result, setResult] = useState([]);

  const [value, setValue] = useState();

  const [notificatons, setNotificatons] = useState([]);

  const [liveNoti, setLiveNoti] = useState();

  const { logout, user, isAuthenticated } = useAuth();

  const navigate = useNavigate();

  // search
  const find = async (val) => {
    setLoading(true);
    setValue(val);
    // const res = await axios(
    const res = await search(
      `/api/search/user/${val}`
    );
    console.log(res);
    setResult(res?.data);
    setLoading(false);
  };

  // get all notifications

  const getNotifications = async () => {
    try {
      const response = await axios.get("/api/get/notifications");
      setNotificatons(response.data.notifications);
      console.log(response.data.notifications, "noti");
    } catch (error) {
      toast.error(`${error.message}`);
      console.error(error);
    }
  };

  // Delete all notifications
  const DeleteAllNotification = async () => {
    try {
      const response = await axios.post("/api/clear/notifications");
      if (response.data.status === true) {
        toast.success("all notifications are deleted");
        getNotifications();
      }
      console.log(response);
    } catch (error) {
      toast.erpor("Something went wrong");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getNotifications();
    }
  }, [liveNoti]);

  useEffect(() => {
    const pusher = new Pusher("0596348ba3102442608f", {
      cluster: "ap2",
      //   encrypted: true,
    });
    const channel = pusher.subscribe(`IS_MESSAGE_${user?.socket_id}`);
    console.log(channel, "channel1");
    channel.bind("is.message", (data) => {
      setLiveNoti(data);
      toast.info(data.message.title);
    });
    console.log(channel, "channel");
    return () => {
      pusher.unsubscribe(`IS_MESSAGE_${user?.socket_id}`);
    };
  }, []);

  //   useEffect(() => {

  // 	if (liveNoti) toast.info(liveNoti.message.title)

  //   }, [liveNoti]);

  const getNotificationListItemData = (data) => {
    // const {message} = data
    // if (condition1) {
    //     //  block of code to be executed if condition1 is true
    //   }else if (condition2) {
    //     //  block of code to be executed if the condition1 is false and condition2 is true
    //   }else if (condition2) {
    //     //  block of code to be executed if the condition1 is false and condition2 is true
    //   }else {
    //     //  block of code to be executed if the condition1 is false and condition2 is false
    //   }
    console.log(data, "====> Notification List Item Data");
    if (data?.media_id === null) {
      navigate(`/user/chat/${data?.sender_id}`);
    } else {
      navigate(`/videodetail/${data?.media_id}`);
    }
  };
  console.log("Normal")
  return (
    <>
      <header className="header-area container">
        <div className="vizew-main-menu" id="sticker">
          <div
            className={`${width > 1150
              ? "classy-nav-container breakpoint-off"
              : "classy-nav-container breakpoint-on"
              }`}
          >
            <div className="container px-4">
              <nav
                className="classy-navbar justify-content-around align-items-center"
                id="vizewNav"
              >
                <div
                  className="d-flex justify-content-around align-items-center"

                >
                  <div className="container no-gutters mx-auto">
                    <div className="w-100">
                      <Iconify
                        icon={"material-symbols:arrow-back"}
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="arrow"
                      />
                    </div>
                  </div>
                  <Link to="/" className="nav-brand">
                    <h2
                      style={{
                        fontFamily: '"Crimes Times Six"',
                        color: "red",
                        fontSize: "3rem",
                      }}
                    >
                      Sarealtv
                    </h2>
                  </Link>
                  {isAuthenticated ? null : (
                    <div className="templatemo_bottom d-md-block d-none">
                      <div className="right">
                        <a href="www.linkedin.com/in/sarealtv">
                          <div
                            style={{ width: "3.5vw" }}
                            className="fa fa-linkedin soc mx-0 mx-md-2"
                          />
                        </a>
                        <a href="https://twitter.com/Sarealtv">
                          <div
                            style={{ width: "3.5vw" }}
                            className="fa fa-twitter soc mx-0 mx-md-2"
                          />
                        </a>
                        <a href="https://www.instagram.com/sarealtv">
                          <div
                            style={{ width: "3.5vw" }}
                            className="fa fa-instagram soc mx-0 mx-md-2"
                          />
                        </a>
                        <a href="https://www.youtube.com/sarealent">
                          <div
                            style={{ width: "3.5vw" }}
                            className="fa fa-youtube-play soc mx-0 mx-md-2"
                          />
                        </a>
                        <a href="https://www.facebook.com/SaRealEnt/?ref=bookmarks">
                          <div
                            style={{ width: "3.5vw" }}
                            className="fa fa-facebook soc mx-0 mx-md-2"
                          />
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-around align-items-center ">
                  {width < 1150 ? (
                    <>
                      {
                        isAuthenticated ?
                          <li className="popover__wrapper noti my-auto">
                            <a href="#" className="iphone-xs-max">
                              <Iconify class='iphone'
                                icon={"ic:outline-notifications"}
                              />
                            </a>
                            <ul
                              className="popover__content"
                              style={{
                                width: "280px",
                                height: "200px",
                                overflowY: "scroll",
                              }}
                            >
                              <li className="notification-item">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => DeleteAllNotification()}
                                >
                                  Clear All{" "}
                                </button>{" "}
                              </li>
                              {notificatons.map((e) => (
                                <li
                                  className="notification-item"
                                  onClick={() => {
                                    getNotificationListItemData(e);
                                  }}
                                >
                                  {e.message}
                                </li>
                              ))}
                            </ul>

                          </li>
                          : null
                      }

                      <li className="popover__wrapper seachbar-small">
                        <a href="#" className="searchbox text-danger">
                          <i style={{ zoom: 1.2 }} className="fa fa-search"></i>
                        </a>
                        <div className="popover__content">
                          <label>Find Friend</label>
                          <div className="form-group">
                            <input
                              name="searchword"
                              value={value}
                              onChange={(e) => find(e.target.value)}
                              maxlength="200"
                              className="form-control"
                              type="search"
                              placeholder="Search ..."
                            />
                            <ul
                              style={{ overflowY: "scroll", maxHeight: "15rem" }}
                            >
                              <PerfectScrollbar>
                                <li>{result?.message}</li>
                                {result?.result?.map(function (i) {
                                  return (
                                    <Link
                                      to={`/profile/${i.id}`}
                                      style={{ width: "200px" }}
                                      onClick={() => {
                                        setValue("");
                                        setResult([]);
                                      }}
                                    >
                                      {" "}
                                      <div className="media user-follower">
                                        <div class="avatar">
                                          <div class="avatar-contianer search_box">
                                            <img
                                              src={
                                                i.picture !== "" &&
                                                  i.picture !== null
                                                  ? i.picture
                                                  : "/assets/website/images/avatar.png"
                                              }
                                              alt="User Avatar"
                                              className="media-object pull-left"
                                            />
                                          </div>
                                        </div>
                                        <div className="media-body">
                                          <p to="#">
                                            {i.name}
                                            <br />
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  );
                                })}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </>
                  ) : null}
                  <div
                    className="classy-navbar-toggler"
                    onClick={() => {
                      Collaspe.onToggleCollapse(true);
                    }}
                  >
                    <span className="navbarToggler">
                      <span />
                      <span />
                      <span />
                    </span>
                  </div>
                </div>
                <div
                  className={`${Collaspe.collapseClick
                    ? "classy-menu menu-on"
                    : "classy-menu "
                    }`}
                >
                  <div
                    className="classycloseIcon"
                    onClick={() => {
                      Collaspe.onToggleCollapse(false);
                    }}
                  >
                    <div className="cross-wrap">
                      <span className="top" />
                      <span className="bottom" />
                    </div>
                  </div>

                  <div className="classynav" style={{ width: "116%" }}>
                    <ul>
                      <li
                        className={`${pathname.includes("/home") ? "active" : null}`}
                      >
                        <Link
                          to="/home"
                          style={{ fontSize: "10pt" }}
                          onClick={() => {
                            Collaspe.onToggleCollapse(false);
                          }}
                        >
                          Home
                        </Link>
                      </li>
                      <li
                        className={`${pathname.includes("search") ? "active" : null
                          }`}
                      >
                        <Link
                          to="/search"
                          style={{ fontSize: "10pt" }}
                          onClick={() => {
                            Collaspe.onToggleCollapse(false);
                          }}
                        >
                          Search
                        </Link>
                      </li>
                      <li
                        className={`${pathname.includes("comingsoon") ? "active" : null
                          }`}
                      >
                        <Link
                          to="/play"
                          style={{ fontSize: "10pt" }}
                          onClick={() => {
                            Collaspe.onToggleCollapse(false);
                          }}
                        >
                          Play
                        </Link>
                      </li>
                      {/* <li
                      className={`${
                        pathname.includes("comingsoon") ? "active" : null
                      }`}
                    >
                      <Link to="/comingsoon" style={{ fontSize: "10pt" }}>
                        New & Trending
                      </Link>
                    </li> */}
                      {/* <li className={`${pathname.includes('comingsoon')?"active":null}`}><Link to="/comingsoon" style={{ fontSize: '10pt'}}>Studio</Link></li> */}

                      <li
                        className={`${pathname.includes("Subscription") ? "active" : null
                          }`}
                      >
                        <Link
                          to="/Subscription"
                          style={{ fontSize: "10pt" }}
                          onClick={() => {
                            Collaspe.onToggleCollapse(false);
                          }}
                        >
                          Subscriptions
                        </Link>
                      </li>


                      <li
                        className={`${pathname.includes("movies") ? "active" : null
                          }`}
                      >
                        <Link
                          to="/movies"
                          style={{ fontSize: "10pt" }}
                          onClick={() => {
                            Collaspe.onToggleCollapse(false);
                          }}
                        >
                          Movies
                        </Link>
                      </li>
                      <li
                        className={`${pathname.includes("tv") ? "active" : null
                          }`}
                      >
                        <Link
                          to="/tvshows"

                          style={{ fontSize: "10pt" }}
                          onClick={() => {
                            Collaspe.onToggleCollapse(false);
                          }}
                        >
                          TV SHOWS
                        </Link>
                      </li>
                      <li
                        className={`${pathname.includes("list") ? "active" : null
                          }`}
                      >
                        <Link
                          to="/user/mylist"
                          style={{ fontSize: "10pt" }}
                          onClick={() => {
                            Collaspe.onToggleCollapse(false);
                          }}
                        >
                          My List
                        </Link>
                      </li>

                      {isAuthenticated ? (
                        <>
                          {width > 1150 ? (
                            <>
                              <li className="cn-dropdown-item has-down">
                                <a href="#" style={{ fontSize: "10px" }}>
                                  {user?.name}
                                </a>
                                <ul className="dropdown">
                                  <li
                                    onClick={() => {
                                      Collaspe.onToggleCollapse(false);
                                    }}
                                  >
                                    <Link to={PATH_USER.profile}>My Profile</Link>
                                  </li>
                                  <li
                                    onClick={() => {
                                      Collaspe.onToggleCollapse(false);
                                    }}
                                  >
                                    <Link to={"/messages"}>Messages</Link>
                                  </li>
                                  <li>
                                    <a onClick={logout} href="">
                                      logout
                                    </a>
                                  </li>
                                </ul>
                                <span className="dd-trigger" />
                              </li>

                              {isAuthenticated && width > 1150 ? (
                                <li className="cn-dropdown-item">
                                  <a href="#" style={{ fontSize: "7px" }}>
                                    <Iconify
                                      icon={"ic:outline-notifications"}
                                      style={{ zoom: 3 }}
                                    />
                                  </a>
                                  <ul
                                    className="dropdown"
                                    style={{
                                      width: "280px",
                                      height: "200px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <li className="notification-item">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() => DeleteAllNotification()}
                                      >
                                        Clear All{" "}
                                      </button>{" "}
                                    </li>
                                    {notificatons.map((e) => (
                                      <li
                                        className="notification-item"
                                        onClick={() => {
                                          getNotificationListItemData(e);
                                        }}
                                      >
                                        {e.message}
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              ) : null}
                              <li className="popover__wrapper">
                                <a href="#" className="searchbox">
                                  <i className="fa fa-search"></i>
                                </a>
                                <div className="popover__content">
                                  <label>Find Friend</label>

                                  <div className="form-group">
                                    <input
                                      name="searchword"
                                      value={value}
                                      onChange={(e) => find(e.target.value)}
                                      maxlength="200"
                                      className="form-control"
                                      type="search"
                                      placeholder="Search ..."
                                    />

                                    <ul
                                      style={{
                                        overflowY: "scroll",
                                        maxHeight: "15rem",
                                      }}
                                    >
                                      <PerfectScrollbar>
                                        <li>{result?.message}</li>
                                        {result?.result?.map(function (i) {
                                          return (
                                            <Link
                                              to={`/profile/${i.id}`}
                                              onClick={() => {
                                                setValue("");
                                                setResult([]);
                                              }}
                                            >
                                              {" "}
                                              <div className="media user-follower">
                                                <div class="avatar">
                                                  <div class="avatar-contianer search_box">
                                                    <img
                                                      src={
                                                        i.picture !== "" &&
                                                          i.picture !== null
                                                          ? i.picture
                                                          : "/assets/website/images/avatar.png"
                                                      }
                                                      alt="User Avatar"
                                                      className="media-object pull-left"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="media-body">
                                                  <p to="#">
                                                    {i.name}
                                                    <br />
                                                  </p>
                                                </div>
                                              </div>
                                            </Link>
                                          );
                                        })}
                                      </PerfectScrollbar>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </>
                          ) : (
                            <>
                              <li>
                                <Link
                                  to={PATH_USER.profile}
                                  style={{ fontSize: "10pt" }}
                                >
                                  My Profile
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={"/messages"}
                                  style={{ fontSize: "10pt" }}
                                >
                                  Messages
                                </Link>
                              </li>

                              <li>
                                <a onClick={logout} style={{ fontSize: "10pt" }}>
                                  logout
                                </a>
                              </li>
                            </>
                          )}
                        </>
                      ) : (
                        <li>
                          <Link to={PATH_AUTH.login} style={{ fontSize: "10pt" }}>
                            login
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default HeaderComponent;
