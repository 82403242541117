/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
//
import PerfectScrollbar from "react-perfect-scrollbar";
// hooks
import useAuth from "../../hooks/useAuth";

// Path
import { PATH_AUTH, PATH_USER } from "../../routes/paths";
// import Iconify from "../Iconify";
//  Utils
import { search } from "../../utils/SearchRequestHandler";
import useWindowDimensions from "../../hooks/getWindowDimensions";
import { useRef } from "react";
import { CollapseDrawerContext } from "../../contexts/IsCollasped";

// -----------------------------------------------------------------------------------
const AuthHeader = () => {
  const Collaspe = useContext(CollapseDrawerContext);
  const menuRef = useRef();

  // const checkClick = (e) => {
  //   console.log("THIS IS MY CLICK");
  //   if (menuRef && menuRef.current && menuRef.current.contains(e.target)) {
  //     console.log("Clicked Inside");
  //   } else {
  //     onToggleCollapse(!IsCollaspe);
  //   }
  // };

  // useEffect(()=>{
  //     document.addEventListener("click",(e)=>checkClick(e));
  //     return ()=>{
  //         document.removeEventListener("click",(e)=>checkClick(e));
  //     }
  // },[])

  const {width } = useWindowDimensions();

  const [, setLoading] = useState(false);

  const { pathname } = useLocation();

  const [result, setResult] = useState([]);

  const [value, setValue] = useState();

  const [IsCollaspe, onToggleCollapse] = useState(false);

  const { logout, user, isAuthenticated } = useAuth();
  console.log(user);

  const [, setIsLoggedIn] = useState(false);
  const [, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    const userIsLoggedIn = true;
    setIsLoggedIn(userIsLoggedIn);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const find = async (val) => {
    setLoading(true);
    setValue(val);
    // const res = await axios(
    const res = await search(
      `https://sarealtvapi.developer-iu.xyz/api/search/user/${val}`
    );
    console.log(res);
    setResult(res?.data);
    setLoading(false);
  };
  console.log("Auth");
  return (
    <>
    <header className="header-area" ref={menuRef}>
      <div className="vizew-main-menu" id="sticker">
        <div
          className={`${
            width > 1150
              ? "classy-nav-container breakpoint-off"
              : "classy-nav-container breakpoint-on"
          }`}
        >
          <div className="container">
            <nav
              className="classy-navbar justify-content-between align-items-center noauth"
              id="vizewNav"
            >
              <div className="d-flex justify-content-around align-items-center mt-1">
                <Link to="/" className="nav-brand">
                  <h2
                    style={{
                      fontFamily: '"Crimes Times Six"',
                      color: "red",
                      fontSize: "3rem",
                    }}
                  >
                    Sarealtv
                  </h2>
                </Link>
                {/* {isAuthenticated ? null : (
                  <div className="templatemo_bottom">
                    <div className="right">
                      <a
                        className="none_360"
                        href="www.linkedin.com/in/sarealtv"
                      >
                        <div
                          style={{ width: "3.5vw" }}
                          className="fa fa-linkedin soc"
                        />
                      </a>
                      <a
                        className="none_360"
                        href="https://twitter.com/Sarealtv"
                      >
                        <div
                          style={{ width: "3.5vw" }}
                          className="fa fa-twitter soc"
                        />
                      </a>
                      <a href="https://www.instagram.com/sarealtv">
                        <div
                          style={{ width: "3.5vw" }}
                          className="fa fa-instagram soc"
                        />
                      </a>
                      <a href="https://www.youtube.com/sarealent">
                        <div
                          style={{ width: "3.5vw" }}
                          className="fa fa-youtube-play soc"
                        />
                      </a>
                      <a href="https://www.facebook.com/SaRealEnt/?ref=bookmarks">
                        <div
                          style={{ width: "3.5vw" }}
                          className="fa fa-facebook soc"
                        />
                      </a>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="d-flex justify-content-center align-items-center">
              {/* {isLoggedIn && screenWidth < 1150 ? (
                  <li className="popover__wrapper seachbar-small">
                    <a href="#" className="searchbox text-danger">
                      <i className="fa fa-search"></i>
                    </a>
                    <div className="popover__content">
                      <label>Find Friend</label>
                      <div className="form-group">
                        <input
                          name="searchword"
                          value={value}
                          onChange={(e) => find(e.target.value)}
                          maxlength="200"
                          className="form-control"
                          type="search"
                          placeholder="Search ..."
                        />

                        <ul style={{ overflowY: "scroll", maxHeight: "15rem" }}>
                          <PerfectScrollbar>
                            <li>{result?.message}</li>
                            {result?.result?.map(function (i) {
                              return (
                                <Link
                                  to={`/profile/${i.id}`}
                                  style={{ width: "200px" }}
                                  onClick={() => {
                                    setValue("");
                                    setResult([]);
                                  }}
                                >
                                  {" "}
                                  <div className="media user-follower">
                                    <div class="avatar">
                                      <div class="avatar-contianer search_box">
                                        <img
                                          src={
                                            i.picture !== "" &&
                                            i.picture !== null
                                              ? i.picture
                                              : "/assets/website/images/avatar.png"
                                          }
                                          alt="User Avatar"
                                          className="media-object pull-left"
                                        />
                                      </div>
                                    </div>
                                    <div className="media-body">
                                      <p to="#">
                                        {i.name}
                                        <br />
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                    </div>
                  </li>
                ) : null} */}
                <div
                  className="classy-navbar-toggler"
                  onClick={() => Collaspe.onToggleCollapse(true)}
                >
                  <span className="navbarToggler">
                    <span />
                    <span />
                    <span />
                  </span>
                </div>
              </div>
              <div
                className={`${
                  Collaspe.collapseClick
                    ? "classy-menu menu-on"
                    : "classy-menu "
                }`}
              >
                <div
                  className="classycloseIcon"
                  onClick={() => Collaspe.onToggleCollapse(false)}
                >
                  <div className="cross-wrap">
                    <span className="top" />
                    <span className="bottom" />
                  </div>
                </div>

                <div className="classynav">
                  {/* {
                                    isAuthenticated ?

                                        null

                                        :

                                        <div className="templatemo_bottom d-md-none d-xs-block" >
                                            <div className="right">
                                                <a href="www.linkedin.com/in/sarealtv">
                                                    <div style={{ width: '3.5vw' }} className="fa fa-linkedin soc" />
                                                </a>
                                                <a href="https://twitter.com/Sarealtv">
                                                    <div style={{ width: '3.5vw' }} className="fa fa-twitter soc" />
                                                </a>
                                                <a href="https://www.instagram.com/sarealtv">
                                                    <div style={{ width: '3.5vw' }} className="fa fa-instagram soc" />
                                                </a>
                                                <a href="https://www.youtube.com/sarealent">
                                                    <div style={{ width: '3.5vw' }} className="fa fa-youtube-play soc" />
                                                </a>
                                                <a href="https://www.facebook.com/SaRealEnt/?ref=bookmarks">
                                                    <div style={{ width: '3.5vw' }} className="fa fa-facebook soc" />
                                                </a>

                                            </div>


                                        </div>
                                } */}
                  <ul>
                    <li
                      className={`${
                        pathname.includes("/home") ? "active" : null
                      }`}
                    >
                      <Link
                        to="/home"
                        onClick={() => Collaspe.onToggleCollapse(!IsCollaspe)}
                      >
                        Home
                      </Link>
                    </li>
                    <li
                      className={`${
                        pathname.includes("search") ? "active" : null
                      }`}
                    >
                      {/* <Link
                        to="/search"
                        style={{ fontSize: "10pt" }}
                        onClick={() => {
                          Collaspe.onToggleCollapse(false);
                        }}
                      >
                        Search
                      </Link> */}
                    </li>
                    <li
                      className={`${
                        pathname.includes("movies") ? "active" : null
                      }`}
                    >
                      <Link
                        to="/movies"
                        onClick={() => Collaspe.onToggleCollapse(false)}
                      >
                        Movies
                      </Link>
                    </li>
                    <li
                      className={`${pathname.includes("tv") ? "active" : null}`}
                    >
                      <Link
                        to="/tvshows"
                        onClick={() => onToggleCollapse(!IsCollaspe)}
                      >
                        TV Shows
                      </Link>
                    </li>
                    <li
                      className={`${
                        pathname.includes("Subscription") ? "active" : null
                      }`}
                    >
                      <Link
                        to="/Subscription"
                        // style={{ fontSize: "10pt" }}
                        onClick={() => {
                          Collaspe.onToggleCollapse(false);
                        }}
                      >
                        Subscriptions
                      </Link>
                    </li>
                    <li
                      className={`${
                        pathname.includes("madrobot") ? "active" : null
                      }`}
                    >
                      <Link
                        to="/madrobot"
                        onClick={() => Collaspe.onToggleCollapse(false)}
                      >
                        Mad Robot
                      </Link>
                      <ul className="dropdown">
                        <li>
                          <Link
                            to="/madrobot/webgl"
                            onClick={() => Collaspe.onToggleCollapse(false)}
                          >
                            - Play Mad Robot
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li
                      className={`${
                        pathname.includes("games") ? "active" : null
                      }`}
                    >
                      <Link
                        to="/games"
                        onClick={() => onToggleCollapse(!IsCollaspe)}
                      >
                        Games
                      </Link>
                    </li>
                    <li
                      className={`${
                        pathname.includes("nfts") ? "active" : null
                      }`}
                    >
                      <Link
                        to="/nfts"
                        onClick={() => onToggleCollapse(!IsCollaspe)}
                      >
                        NFT's
                      </Link>
                    </li> */}
                    {/* <li className={`${pathname.includes('studio')?"active":null}`}><Link to="/studio" style={{ fontSize: '10pt'}}>Studio</Link></li> */}
                    {/* <li
                      className={`${
                        pathname.includes("digitallocker") ? "active" : null
                      }`}
                    >
                      <Link
                        to="/digitallocker"
                        onClick={() => onToggleCollapse(!IsCollaspe)}
                      >
                        Digital Locker
                      </Link>
                      <ul className="dropdown">
                        <li>
                          <Link
                            to="/videos"
                            onClick={() => onToggleCollapse(!IsCollaspe)}
                          >
                            - Videos
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/photos"
                            onClick={() => onToggleCollapse(!IsCollaspe)}
                          >
                            - Photos
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/motiongraphics"
                            onClick={() => onToggleCollapse(!IsCollaspe)}
                          >
                            - Motion Graphics
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/cartoonanimations"
                            onClick={() => onToggleCollapse(!IsCollaspe)}
                          >
                            - Cartoon Animations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/visualfx"
                            onClick={() => onToggleCollapse(!IsCollaspe)}
                          >
                            - Visual Fx
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/movies"
                            onClick={() => onToggleCollapse(!IsCollaspe)}
                          >
                            - Movies
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/publishedphotos"
                            onClick={() => onToggleCollapse(!IsCollaspe)}
                          >
                            - Published Photos
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/socialmedia"
                            onClick={() => onToggleCollapse(!IsCollaspe)}
                          >
                            - Social Media
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    <li
                      className={`${
                        pathname.includes("contactus") ? "active" : null
                      }`}
                    >
                      <Link to="/contactus">Contact</Link>
                    </li>
                    {isAuthenticated ? (
                      <>
                        {width > 1000 ? (
                          <>
                            <li className="cn-dropdown-item has-down">
                              <a href="#" style={{ fontSize: "10pt" }}>
                                {user?.name}
                              </a>
                              <ul className="dropdown">
                                <li>
                                  <Link to={PATH_USER.profile}>My Profile</Link>
                                </li>
                                <li>
                                  <a onClick={logout} href="">
                                    logout
                                  </a>
                                </li>
                              </ul>
                              <span className="dd-trigger" />
                            </li>

                            <li className="popover__wrapper">
                              <a href="#" className="searchbox">
                                <i className="fa fa-search"></i>
                              </a>
                              <div className="popover__content">
                                <label>Find Friend</label>

                                <div className="form-group">
                                  <input
                                    name="searchword"
                                    value={value}
                                    onChange={(e) => find(e.target.value)}
                                    maxlength="200"
                                    className="form-control"
                                    type="search"
                                    placeholder="Search ..."
                                  />

                                  <ul
                                    style={{
                                      overflowY: "scroll",
                                      maxHeight: "15rem",
                                    }}
                                  >
                                    <PerfectScrollbar>
                                      <li>{result?.message}</li>
                                      {result?.result?.map(function (i) {
                                        return (
                                          <Link
                                            to={`/profile/${i.id}`}
                                            onClick={() => {
                                              setValue("");
                                              setResult([]);
                                            }}
                                          >
                                            {" "}
                                            <div className="media user-follower">
                                              <div class="avatar">
                                                <div class="avatar-contianer search_box">
                                                  <img
                                                    src={
                                                      i.picture !== "" &&
                                                      i.picture !== null
                                                        ? i.picture
                                                        : "/assets/website/images/avatar.png"
                                                    }
                                                    alt="User Avatar"
                                                    className="media-object pull-left"
                                                  />
                                                </div>
                                              </div>
                                              <div className="media-body">
                                                <p to="#">
                                                  {i.name}
                                                  <br />
                                                </p>
                                              </div>
                                            </div>
                                          </Link>
                                        );
                                      })}
                                    </PerfectScrollbar>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link to={PATH_USER.profile}>My Profile</Link>
                            </li>
                            <li>
                              <a onClick={logout}>logout</a>
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      <li
                        className={`${
                          pathname.includes("login") ? "active" : null
                        }`}
                      >
                        <Link to={PATH_AUTH.login}>login</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <style>
      {
        `
        .breakpoint-on .classynav ul li{
          background-color : black;
        }
        `
      }
    </style>
    </>
  );
};
export default AuthHeader;
