import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
// 
import {  Button, Dropdown, Modal } from 'react-bootstrap';
// 
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton, FacebookMessengerShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, LinkedinIcon, FacebookMessengerIcon } from "react-share";
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
// 
import axios from '../../../utils/axios';
// 
// import PropTypes from 'prop-types';

// 
import { FaCommentDots } from "react-icons/fa";
// import { FcShare } from "react-icons/fc";
// 
import { FormProvider } from '../../hook-form';
import LoadingButton from '../../LoadingButton';
import Iconify from '../../Iconify';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';
import MUIDataTable from 'mui-datatables';
import LoadingScreen from '../../LoadingScreen';
import { Link } from 'react-router-dom';

// ---------------------------------------------------------------------------

const Videodetails = ({ name, title,videoId, isLiked, description, likeCount, commentCount, clientId, date, onFollow, onLike, loading, img, ...others }) => {

  const [show, setShow] = useState(false);

  const [searchText,setSearchText] = useState()

  const handleClose = () => setShow(false);

  // const handleShow = () => setShow(true);

  const [load, setLoad] = useState(false)

  const [formError, setFormError] = useState([])

  const [allBookMark, setAllBookMark] = useState([])

  // const isMountedRef = useIsMountedRef();

  // table Columns ---------------------------------------------
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "title",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "Actoion",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (

          <LoadingButton
            buttonText={"Add into list"}
            loadingText={"...loading"}
            animation='border'
            onClick={() => addVideoIntoBookMark(tableMeta.rowData[0], videoId)}
          />
        )
      }
    },

  ];


  const options = {
    selectableRows: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onSearchChange:(e)=>setSearchText(e),
    textLabels: {
      body: {
        noMatch:`${searchText?`Your search ${searchText} couldn't be found`:'No Listed Created'}`, // this would be whatever you want the message to say
      }
    },
  };

  //get bookmark list

  const getBookmarks = async () => {
    setLoad(true)
    try {

      const response = await axios.get('/api/all/list/fetch')
      console.log(response)
      if (response.data.status) {
        setLoad(false)

        setAllBookMark(response.data.list_name)
      } else {
        setLoad(false)

      }
    } catch (error) {
      setLoad(false)

      toast.error(`${error.message}`)

    }
  }


  // book mark form Config -------------------------------------------------
  const BookMarkSechma = Yup.object().shape({
    list_name: Yup.string().required("name  is required"),
    list_type: Yup.string().required("type is description").oneOf(['Public', 'Private']),
  });

  const uploadDefaultValue = {
    media: "",
    title: "",
    duration: '',
    description: '',
  };

  const addBookMark = useForm({
    resolver: yupResolver(BookMarkSechma),
    uploadDefaultValue,
  });

  const {
    reset,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = addBookMark;
  const onSubmit = async (data) => {
    setLoad(false)
    const formdata = new FormData()
    formdata.append('type', data.list_type)
    formdata.append('title', data.list_name)
    try {
      const response = await axios.post('/api/list/add', formdata);
      console.log(response)
      if (response.data.message) {
        setLoad(false)

        toast.success("Success", "Created Successfully", 'success')
        getBookmarks();
      }
      else {
        setLoad(false)

        Swal.fire("Something went wrong", " ", 'error')

      }
    } catch (error) {
      setLoad(false)

      toast.error(`${error.message}`)
      console.log(error);
      reset();
      const keys = Object.keys(error.errors);
      const err = []
      keys.forEach((key, i) => {
        console.log(key, i)
        err.push(`${key}: ${error.errors[key]}`)
        console.log(`${key}: ${error.errors[key]}`);


      });
      setFormError(err)

      setError('afterSubmit', error);
    }
  };


  const addVideoIntoBookMark = async (listid, mediaId) => {
    setLoad(true)
    try {
      const response = await axios.post(`/api/media/list/add/${mediaId}/${listid}`);
      console.log(response)
      if (response.data.message) {
        toast.success("Success", "Added Successfully", 'success')
        getBookmarks();
        setLoad(false)
      }
      else {
        setLoad(false)

        Swal.fire("Something went wrong", " ", 'error')

      }
    } catch (error) {
      setLoad(false)
      toast.error(`${error.message}`)
      console.log(error);
      reset();
      const keys = Object.keys(error.errors);
      const err = []
      keys.forEach((key, i) => {
        console.log(key, i)
        toast.error(`${key}: ${error.errors[key]}`)
        console.log(`${key}: ${error.errors[key]}`);


      });
      setFormError(err)

    }
  }

  useEffect(() => {
    getBookmarks()
  }, [])
  return (
    <>
      {
        load ?
          <LoadingScreen />
          :
          <>
            <div className='container-fluid max-height-vh-100 no-gutters mt-5' >
              <div className='row  no-gutters'>
                <div className="col-md-12 d-flex justify-content-start align-items-center">

                  <div class="avatar">
                    <div class="avatar-contianer">
                      <img src={img !== '' ? img : '/assets/website/images/Title.png'} alt="Profile Img" />
                    </div>

                  </div>

                  <div className="user_name ms-1">
                    <span className='d-flex'>
                      <h3>{title}</h3>
                      &nbsp;
                      {/* <img src="assets/website/images/checked.png" alt="Blue Tick" style={{ width: "18px", height: "21px" }} /> */}
                    </span>
                    <p className='text-left'>Posted at
                      &nbsp;
                      <span>
                        {date}
                      </span>
                      &nbsp;
                      <span>

                      </span>
                    </p>

                  </div>
                  {/* <button className='ml-auto btn border-none  fs-1  bg-transparent' onClick={handleShow}   data-toggle="tooltip" data-placement="bottom" title="Add to list" >
                    <Iconify icon={"bi:bookmark-plus-fill"} style={{ zoom: "2", color: "white" }} />
                  </button> */}
                </div>

              </div>
              <div className="details mt-4">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10" >
                    <h4 style={{
                      maxHeight: '13vh',
                      minHeight: '12vh',
                      overflowX: 'hidden',
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginLeft: '30px'
                    }}>
                      {description}
                    </h4>
                    <div className="share mt-5">
                      <div className="d-flex  justify-content-around align-items-center shadow-sm">
                        <div className="col-md-3 col-sm-3 col-xs-3">
                          <div className='text-white'>
                            <span className="d-flex">
                              {
                                isLiked ?
                                  <Iconify icon={'ant-design:heart-filled'} onClick={() => onLike(videoId)} className='heart-liked' style={{ zoom: 2.5 }} />
                                  :
                                  <Iconify icon={'ant-design:heart-filled'} onClick={() => onLike(videoId)} className='heart' style={{ zoom: 2}} />
                              }

                              <h5 className='mt-11px mr-1'>{likeCount} </h5>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-3">
                          <div className='text-white'>
                            <span className="d-flex">

                              <button className='btn border-none  fs-1  bg-transparent'>
                                <FaCommentDots style={{ zoom: 2.4, color: "white" }} />
                              </button>
                              <h5 className='mt-11px'>{commentCount}</h5>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-3 mt-1">
                          <Dropdown>
                            <Dropdown.Toggle variant="transparent" style={{ background: "transparent", color: "white" }} id="dropdown-basic">
                              <Iconify icon={'fe:share'} style={{ zoom: 3.5, float: "right", color: "white" }} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <FacebookShareButton
                                  url={`https://sarealtv.com/videodetail/${videoId}`}
                                  quote={`${name}`}
                                  hashtag={"#hashtag"}
                                  description={description}
                                  className="Demo__some-network__share-button"
                                >
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <WhatsappShareButton
                                  url={`https://sarealtv.com/videodetail/${videoId}`}
                                  quote={`${name}`}
                                  hashtag={"#hashtag"}
                                  description={description}
                                  className="Demo__some-network__share-button"
                                >
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <TwitterShareButton
                                  url={`https://sarealtv.com/videodetail/${videoId}`}
                                  quote={`${name}`}
                                  hashtag={"#hashtag"}
                                  description={description}
                                  className="Demo__some-network__share-button"
                                >
                                  <TwitterIcon size={32} round />
                                </TwitterShareButton>
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <LinkedinShareButton
                                  url={`https://sarealtv.com/videodetail/${videoId}`}
                                  quote={`${name}`}
                                  hashtag={"#hashtag"}
                                  description={description}
                                  className="Demo__some-network__share-button"
                                >
                                  <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <FacebookMessengerShareButton
                                  url={`https://sarealtv.com/videodetail/${videoId}`}
                                  quote={`${name}`}
                                  hashtag={"#hashtag"}
                                  description={description}
                                  className="Demo__some-network__share-button"
                                >
                                  <FacebookMessengerIcon size={32} round />
                                </FacebookMessengerShareButton>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-3 " data-toggle="tooltip" data-placement="bottom" title="Message" >
                          <div className='text-white'>
                            <span className="d-flex">

                              <Link to={`/user/chat/${clientId}`}
                                className="text-white"

                                style={{ zoom: 2, color: "white" }}
                              >
                                <Iconify icon={'ri:send-plane-fill'} />
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>

              </div>
            </div>
            <Modal show={show} animation={false} size="lg" aria-labelledby="example-modal-sizes-title-lg" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Create / Add to  List</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormProvider methods={addBookMark} onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">  {!!errors.afterSubmit && <div className="error-msg">{formError.map((err) => <p className="text-danger">{err}</p>)}</div>} </div>
                  <p style={{ width: "100%", color: "red", fontSize: "10px" }}>
                    {errors.list_type?.message}
                  </p>
                  <p style={{ width: "100%", color: "red", fontSize: "10px" }}>
                    {errors.list_name?.message}
                  </p>
                  <div class="input-group mb-3">
                    <input name="list_name" type="text" className="form-control" placeholder="List Name" aria-label="List Name" aria-describedby="basic-addon2"  {...register("list_name")} />
                    <div className="input-group-append">
                      <select name="list_type" type="text" className="form-control"  {...register("list_type")} >
                        <option selected >Visibility</option>
                        <option value={"Private"}>Private</option>
                        <option value={"Public"}>Public</option>

                      </select>

                    </div>
                    <div class="input-group-append">

                      <LoadingButton
                        className="m-t-10 waves-effect waves-dark btn btn-primary btn-lg btn-rounded "
                        animation="grow"
                        size="sm"
                        role="status"
                        buttonText={"Create"}
                        loadingText={"...loading"}
                        isDisabled={loading}
                        type={"submit"}

                      />
                    </div>
                  </div>

                </FormProvider>

                {/* <ul class="list-group">
          {
            allBookMark.map((e)=>
            
            <li class="list-group-item row algin-items-center no-gutters" >
              <div className='col-md-8'>{e.title}</div>
              <div className='col-md-2'>{e.type}</div>
              <div className='col-md-2'>
                
              <LoadingButton 
                buttonText={"Add"}
              />
              </div>
            </li>
            )
          }
          </ul> */}

                <MUIDataTable
                  title={"all list"}
                  data={allBookMark}
                  columns={columns}
                  options={options}
                />

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }

    </>
  )
}

export default Videodetails