import { createContext, useState } from 'react';
// hooks
// import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

const initialState = {
  collapseClick: false,
  onToggleCollapse: () => {},
};

const CollapseDrawerContext = createContext(initialState);

// ----------------------------------------------------------------------


function CollapseDrawerProvider({ children }) {

  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });

;

  const handleToggleCollapse = (isOpenAllowd) => {
    if(isOpenAllowd===true){

        setCollapse({ ...collapse, click: !collapse.click });
    }else{
        setCollapse({ ...collapse, click: false });

    }
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse.click && !collapse.hover,
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        onToggleCollapse: handleToggleCollapse,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };