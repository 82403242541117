import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Form } from "react-bootstrap";

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, ...other }) {
  //function
  const { control } = useFormContext();

  return (
    //content
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Form.Control
            {...field}
            {...other}
            isInvalid={!!error?.message}
            isValid={error?.message && !error?.message}
          />
      <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>

        </>
      )}
    />
  );
}
