import React from 'react'
import CommentForm from './CommentForm'
import CommentList from './CommentList'

const comment = ({comment,loading,getComment,getVideo,...others}) => {
  return (
    <>
      <div className='container-fluid no-gutters mt-3'>
        <div className="section-heading style-2" style={{ marginBottom: 0, marginTop: '10px' }}>
          <h4>Comment</h4>
          <div className="line" />
        </div>
        <CommentList comments={comment} />
        <CommentForm  getComment={getComment} getVideo={getVideo}/>
      </div>
    </>
  )
}

export default comment