
import React from 'react'
// import ReactPlayer from 'react-player'
// 
// import Image from "../../Image";

const videoPlayer = ({url,...other}) => {
  return (
<>
<div className='video_height'>
<video src={url}  {...other} autoPlay  controls className='video_style'/>
</div>
</>
  )
}

export default videoPlayer