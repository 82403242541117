/* eslint-disable no-unused-vars */
// routes
import { PATH_USER } from './routes/paths.js';


// 
export const HOST_API="https://sarealtvapi.developer-iu.xyz"

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = '/user/editprofile'; // as '/dashboard/app'

