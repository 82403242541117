import React from "react";
// import oval
import { Oval } from "react-loader-spinner";

export default function LoadingScreen({ isDashboard, ...other }) {
  return (
    <>
      <Oval wrapperClass="Loader" secondaryColor="#fff" color="#ff0000" />

      {/* {!isDashboard && (
        <Oval wrapperClass="Loader" secondaryColor="#1043ec" color="#ffd307" />
      )} */}
    </>
  );
}
