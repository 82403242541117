import React, { useState } from "react";
import * as Yup from 'yup';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
// bootstrap
import {  Form, InputGroup } from 'react-bootstrap';
// routes

// hooks
// import useIsMountedRef from '../../../hooks/useIsMountedRef';
// component
import { FormProvider } from '../../hook-form';
// utils
import axios from "../../../utils/axios";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import LoadingButton from "../../LoadingButton";
import useAuth from "../../../hooks/useAuth";
import { PATH_AUTH } from "../../../routes/paths";

// --------------------------------------------------------------------------

function CommentForm({ clientId, getComment,getVideo, ...other }) {

  // hooks
  const { isAuthenticated } = useAuth();

  const { id } = useParams()

  const [loading, setLoading] = useState(false);

  const addCommentSchema = Yup.object().shape({
    comment: Yup.string(),
  }
  );

  const methods = useForm({
    resolver: yupResolver(addCommentSchema),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  console.log(errors  , isSubmitting);
  
  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const formData = new FormData();
      formData.append('comments', data.comments)


      const response = await axios.post(`/api/admin/media/add/comments/${id}`, formData);
      getComment();
      reset();
      setLoading(false)

      // console.log(response)
      if (response.data.message) {
        toast.success("Success", "Comment add successfully", 'success')
        setLoading(false)
        getVideo()

      }
      else {
        Swal.fire("Something went wrong", " ", 'error')
        setLoading(false)


      }

    } catch (error) {

      Swal.fire("Something went wrong", `${error.message}`, 'error')
      setLoading(false)

      console.error(error);
      reset();

    }
  };
  return (

    <>
      {/* Post A Comment Area */}
      <div className="post-a-comment-area">

        {/* Reply Form */}

        {
          isAuthenticated ?
            <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods} >
              <div className="row">
                <div className="col-12">
                  <InputGroup>
                    <Form.Control as="textarea" aria-label="With textarea" {...register('comments')} />
                    <LoadingButton
                      className="btn btn-primary "
                      style={{ fontSize: '2em', fontFamily: 'LemonMilk' }}
                      animation='border'
                      isDisabled={loading}
                      role='status'
                      buttonText="Comment"
                      type='submit'
                      loadingText={'...loding'}
                    />
                  </InputGroup>
                </div>
              </div>
            </FormProvider>
            :
            <Link to={PATH_AUTH.login} className="btn btn-primary " style={{ fontSize: '2em', fontFamily: 'LemonMilk' }}>Sign in to comment</Link>
        }
      </div>
    </>
  )
}

export default CommentForm