import React from "react";
import HeaderComponent from "../../components/layout/HeaderComponent";

const Header = () =>{
    return(
        <>
            <HeaderComponent />
        </>
    )
}
export default Header