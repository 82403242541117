/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
// bootstrap
import { Alert, InputGroup } from 'react-bootstrap';
// routes
// import { PATH_AUTH } from '../../../routes/paths';

// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { FormProvider, RHFTextField } from '../../components/hook-form';
import Iconify from '../Iconify';
import { useState } from 'react';
import { PATH_USER } from '../../routes/paths';
import Loader from '../Loader';
import LoadingButton from '../LoadingButton';
import { toast } from 'react-toastify';


const LoginComponent = () => {
    const { login } = useAuth();

    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const isMountedRef = useIsMountedRef();

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const defaultValues = {
        email: '',
        password: '',
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        try {

            setLoading(true)
            const response = await login(data.email, data.password);

            if (response.data.message && response.data.status !== false) {
                if(response.data.user.visits === 1){
                    console.log(response.data.user.visits,"user.visits from login 1")
                    navigate(PATH_USER.editPrfile)
                }else{
                    console.log(response.data.user.visits,"user.visits from login >1")
                    navigate(PATH_USER.profile)
                }
                
                setLoading(false)
            }
            else {
                if(response?.data?.errors?.email[0] === 'The selected email is invalid.'){
                toast.error(response.data.errors.email[0])

                }else{
                    navigate(`/activation?email=${data.email}`)
                    toast.error("email not verified")
                }
                // toast.error(response.data.message)
                setLoading(false)

            }


        } catch (error) {
            console.error(error);
            Swal.fire("Something went wrong", `${error.message}`, 'error')

            setLoading(false)

            reset();
            if (isMountedRef.current) {
                setError('afterSubmit', error);
            }
        }
    };

    return (
        <>

            <center>
                <div className="container">
                    <h2 style={{ fontFamily: '"Crimes Times Six"', fontSize: '70px', color: 'white', padding: '10px' }} />
                    <div className="right" style={{ padding: '0px', backgroundColor: '#111111' }}>
                        <Link to="/auth/login" style={{ fontSize: '10rem', color: 'white', fontFamily: '"Crimes Times Six"' }}>Sign In</Link>
                    </div>
                </div>
            </center>
            <div className="templatemo_lightgrey_about" id="templatemo_about" style={{ backgroundColor: '#111111' }}>
                <center>
                    <h2 style={{ color: 'white' }}>Welcome Back!</h2>
                    <br />
                    <div className="container">
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <div className='form-horizontal' role='form'>
                                <div className="form-group">  {!!errors.afterSubmit && <div className="error-msg">{errors.afterSubmit.message}</div>} </div>
                                <h2 style={{ fontSize: '3em', fontFamily: 'LemonMilk' }}>Login</h2>
                                <br />
                                <br />
                                <div className="form-group">
                                    <label htmlFor="email" className="col-sm-3 control-label text_align" style={{ color: 'black' }}>Email* </label>
                                    <div className="col-sm-9">
                                        <RHFTextField type="email" id="email" placeholder="Email" name="email" />

                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="col-sm-3 control-label text_align" style={{ color: 'black' }}>Password*</label>
                                    <div className="col-sm-9">
                                        <InputGroup>
                                            <RHFTextField type={showPassword ? "text" : "password"} name='password' id="password" placeholder="Password" />
                                            <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>

                                                <Iconify width="20px" height="20px" icon={showPassword ? "akar-icons:eye-slashed" : "akar-icons:eye"} />
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-9 text_align">
                                        <span className="">*Required fields</span>
                                    </div>
                                </div>
                                <LoadingButton
                                    className="btn btn-primary btn-block"
                                    style={{ fontSize: '2em', fontFamily: 'LemonMilk' }}
                                    animation='border'
                                    isDisabled={loading}
                                    role='status'
                                    buttonText="Login"
                                    type='submit'
                                    loadingText={'...loading'}
                                />
                            </div>
                        </FormProvider>
                    </div>
                    <br />
                    <div className="d-sm-table-row" style={{ margin: '0px', width: '50vw'}}>
                        <p>
                            <strong style={{ fontSize: '15px', color: 'white' }}>
                                <Link to='/auth/signup' style={{ color: 'white' }}>Create An</Link>{" "}
                                <Link to='/auth/signup' style={{ color: 'red',marginLeft:'1px' }} >Account</Link>
                            </strong>
                        </p>
                        <div><Link to='/contactus'>Forgot Password?</Link></div>
                    </div>
                </center>
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>


        </>
    )
}
export default LoginComponent