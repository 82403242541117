/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

 const FooterComponent = () => {
    return (
        <div>
            <div className="templatemo_footer">
                <div className="container">
                    <div className="col-xs-6 col-sm-6 col-md-3 templatemo_col12 respon">
                        <h2 style={{ color: 'white' }}>About Sarealtv™</h2>
                        <p style={{ color: '#FFFFFF', textAlign: "left" }}>We are a growing production company that provides multimedia services to help small business and entertainers. We create custom digital art unique to every project. Please feel free to contact us for an estimate or to set an appointment.</p>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3 templatemo_col12 respon">
                        <h2 style={{ color: 'white' }}>Services</h2>
                        <ul style={{ color: 'white' }}>
                            <li>Motion Graphics</li>
                            <li>Commercials</li>
                            <li>Music Videos</li>
                            <li>Augmented Reality</li>
                            <li>3D Animations</li>
                        </ul>
                        <div className="clear" />
                        <div className="templatemo_morelink"><a href="#">and more... </a></div>
                    </div>
                     <div className="col-xs-6 col-sm-6 col-md-3 templatemo_col12 respon">
                        <h2 style={{ color: 'white' }}>Services</h2>
                        <ul className="nobullet footer_gallery">
                                <Link to="privacypolicy" style={{ color: 'lightgreen' }}>
                                    <li>Privacy Policy</li><br />
                                    <span>
                                    <Link href="termsofservices" style={{ color: 'lightgreen' }}>
                                    <li>Terms Of Service</li>
                                </Link>
                                    </span>
                                </Link>
                            </ul>
                        <div className="clear" />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3 templatemo_col12 ">
                        <h2 style={{ color: 'white' }} className="txtcntr">Contact</h2>
                        <span className="left col-xs-1 fa fa-map-marker ml-55 " />
                        <span className="right col-xs-11 w-53" style={{ color: 'white', }}>2620 N. Hiawassee Rd. Orlando, FL 32703</span>
                        <div className="clear height10" />
                        <span className="left col-xs-1 fa fa-phone ml-55 " />
                        <span className="right col-xs-11 w-48" style={{ color: 'white', }}>(407) 534-3700</span>
                        <div className="clear height10" />
                        <span className="left col-xs-1 fa fa-envelope ml-55 " />
                        <span className="right col-xs-11 w-48" style={{ color: 'white', }}>support@sarealtv.com</span>
                        <div className="clear height10" />
                        <span className="left col-xs-1 fa fa-globe ml-55 " />
                        <span className="right col-xs-11 w-48" style={{ color: 'white', }}>www.sarealtv.com</span>
                        <div className="clear" />
                    </div>
                </div>
            </div>
         
        </div>

    )
}
export default FooterComponent