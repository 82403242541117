/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
  }
  
  const ROOTS_AUTH = '/auth';
  const ROOTS_DASHBOARD = '/dashboard';
  const ROOT_USER = '/user';

  
  // ----------------------------------------------------------------------
  
  export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    register: path(ROOTS_AUTH, '/register'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify')
  };
  
  export const PATH_PAGE = {
    home:'/',
    movies:'/movies',
    tv_serial:'/tv_serial',
    tv_serial_detail:'/tv_serial_detail',
    movie_detail:'/movie_detail',
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page404: '/404',
    page500: '/500',
    components: '/components'
  };

  
  export const PATH_USER = {
    root: ROOT_USER,

      profile: path(ROOT_USER, '/profile'),
      editPrfile: path(ROOT_USER, '/editprofile'),
      mylist: path(ROOT_USER, '/mylist'),
      listview: path(ROOT_USER, '/listview'),

  };
  