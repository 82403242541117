import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Form } from "react-bootstrap";

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFSelect({ name, children, ...other }) {
  const { control } = useFormContext();

  return (
    //content  section
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Form.Control
            {...field}
            // select
            fullWidth
            isInvalid={!!error?.message}
            isValid={error?.message && !error?.message}
            as="select"
          >
            {children}
          </Form.Control>
          <div className="invalid-feedback">{error?.message}</div>
        </>
      )}
    />
  );
}
